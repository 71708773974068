<template>
  <BaseModal
    name="modal-permission-group"
    size="lg"
    :title="$t('seller.settings.modal_permission_group.text_754')"
    @shown="openModal"
    @hidden="reset"
  >
    <b-row>
      <b-col cols="12">
        <b-form novalidate v-if="!loading">
          <b-row class="mb-3">
            <b-col cols="6">
              <b-form-group
                :label="$t('seller.settings.modal_permission_group.text_755')"
                label-for="date-in"
              >
                <b-form-input
                  id="name"
                  name="name"
                  v-model="name"
                  type="text"
                  v-validate="'required'"
                  :placeholder="
                    $t('seller.settings.modal_permission_group.text_2657')
                  "
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t("seller.settings.modal_permission_group.text_2658") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.settings.modal_permission_group.text_2659')"
                label-for="date-in"
              >
                <div>
                  <!-- select all permissions checkbox -->
                  <div class="grid-list-itens">
                    <div>
                      <div class="d-flex itens">
                        <img :src="getImgUrl('store')" alt="img" />

                        <div>
                          <p>Selecionar todos</p>
                          <span
                            >Habilita/desabilita todas as permissões
                            disponíveis</span
                          >
                        </div>
                      </div>
                    </div>
                    <div>
                      <b-form-checkbox
                        v-model="permission.check"
                        @change="checkAll"
                        name="check-button"
                        switch
                        size="lg"
                        class="ml-switch"
                      ></b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div
                  class="grid-list-itens"
                  v-for="(permission, index) in permissions"
                  :key="permission.id"
                >
                  <div>
                    <div class="d-flex itens">
                      <img :src="getImgUrl(permission.type)" alt="img" />

                      <div>
                        <p>{{ permission.title }}</p>
                        <span>{{ permission.description }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="permission.check"
                      @change="changeCheck(permission, index)"
                      name="check-button"
                      switch
                      size="lg"
                      class="ml-switch"
                    ></b-form-checkbox>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div
          v-if="loading"
          class="
            Table-body
            py-4
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="secondary" :disabled="loading" @click="onSubmit">
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import SubUsersPermissionsService from "@/services/resources/SubUsersPermissionsService";
const serviceSubUsersPermissions = SubUsersPermissionsService.build();
import SubUsersGroupService from "@/services/resources/SubUsersGroupService";
const serviceSubGroup = SubUsersGroupService.build();
// import SubUsersService from "@/services/resources/SubUsersService";
// const serviceSubUsers = SubUsersService.build();

import Multiselect from "vue-multiselect";

export default {
  name: "ModalPermissionGroup",
  props: ["allData"],
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: true,
      name: "",
      permission: [],
      permissions: [],
    };
  },
  methods: {
    checkAll(checked) {
      const newPermissions = Object.assign([], this.permissions);
      newPermissions.forEach((element) => {
        element.check = checked;
      });
      this.permissions = Object.assign([], newPermissions);
    },
    changeCheck(data, index, check = null, prev = null) {
      var permissions = Object.assign([], this.permissions);
      permissions[index].check = check ?? data.check;
      //select all dependents permissions (children attribute) recursively
      if (data.children?.length && !prev) {
        data.children.forEach((element) => {
          if (prev !== element.id) {
            var i = permissions.findIndex((x) => x.id === element.id);
            this.changeCheck(permissions[i], i, data.check);
          }
        });
      } else {
        //select all parent permissions (parent attribute) recursively
        if (data.parent && this.permissions[index].check) {
          var i = permissions.findIndex((x) => x.id === data.parent.id);
          this.changeCheck(permissions[i], i, data.check, data.id);
        }
      }

      this.permissions = Object.assign([], permissions);
    },
    reset() {
      this.name = "";
      this.permission = [];
      this.permissions = [];
    },

    fetchPermissions() {
      this.loading = true;

      serviceSubUsersPermissions
        .read("")
        .then((response) => {
          this.permissions = response;

          if (this.allData.length !== 0) {
            this.name = this.allData.name;
            var permissions = this.permissions;
            var permissionsData = this.allData.sub_user_role_permission;

            for (let i = 0; i < permissionsData.length; i++) {
              const myPermissions = permissionsData[i];
              for (let x = 0; x < permissions.length; x++) {
                const element = permissions[x];
                if (myPermissions.permission_id === element.id) {
                  permissions[x].check = true;
                }
              }
            }
            this.permission = permissions;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let permissions = this.permissions;
          let permission = permissions.filter((x) => x.check === true);
          if (permission.length) {
            var id_permissions = [];
            permission.forEach((element, index) => {
              id_permissions.push(element.id);
            });

            let data = {
              name: this.name,
              permission: id_permissions,
            };

            if (this.allData.length !== 0) {
              data.id = this.allData.id;
              console.log("data -->", data);
              serviceSubGroup
                .update(data)
                .then((response) => {
                  this.resolveResponse();
                })
                .catch((err) => {
                  console.log(err);
                });

              return;
            }

            serviceSubGroup
              .create(data)
              .then((response) => {
                this.resolveResponse();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$bvToast.toast(
              this.$t("seller.settings.modal_permission_group.text_2661"),
              {
                title: this.$t(
                  "seller.settings.modal_permission_group.text_2660"
                ),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        }
      });
    },
    resolveResponse() {
      this.$bvToast.toast(
        this.$t("seller.settings.modal_permission_group.text_2662"),
        {
          title: this.$t("seller.settings.modal_permission_group.text_2660"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
      this.$emit("close");
      this.reset();
      this.$root.$emit("bv::hide::modal", "modal-permission-group", "#btnShow");
    },
    openModal() {
      this.fetchPermissions();
    },
    getImgUrl(icon) {
      var images = require.context(
        "@/assets/img/permissions/",
        false,
        /\.svg$/
      );
      return images("./" + icon + ".svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-list-itens {
  display: grid;
  grid-template-columns: calc(100% - 150px) 150px;
  gap: 20px;
  margin-top: 30px;
  .itens {
    gap: 20px;
    align-items: flex-start;
    border-bottom: solid 1px #ededf0;
    padding-bottom: 10px;
    p {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      margin-bottom: 5px;
      line-height: 1;
    }
    span {
      font-weight: 400;
      font-size: 13px;
      color: #81858e;
    }
  }
}

.ml-switch {
  margin-left: 35px;
}

h3 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 12px;
  font-weight: 300;
}
.grid-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-input {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 3px;
  .copy {
    border: none;
    color: #333;
    background: #f7f7f7;
    outline: none !important;
    font-weight: 600;
    height: auto;
    padding: 0 42px !important;
    border-radius: 5px;
    font-size: 14px;

    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.code {
  background: rgb(27, 26, 29);
  color: white;
  height: auto;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  border-radius: 3px;
}
</style>
